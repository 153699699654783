.projects__category__slider {
  margin-top: 1em;

  .overlay-container {
    position: relative;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(133, 109, 86, 0.6);
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  @include respond-to('max-small') {
    @include standard-dimensions;
    padding-top: 0;
  }

  .projects__slider {
    display: none;

    &.active {
      display: block;
    }

    a {
      text-decoration: none;
      color: #000;
    }

    .project-slide {
      transition: transform 300ms ease;
      padding: 2rem 0;
    }

    .splide__slide__info {
      .splide__slide__info__category {
        font-size: 0.9rem;
        font-weight: $weight-light;
        line-height: 0.7rem;
        padding-top: 1em;
      }

      .splide__slide__info__client {
        font-weight: $weight-bold;
        font-size: 1rem;
      }

      .splide__slide__info__img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
