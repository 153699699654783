.standard-button {
  all: unset;
  color: #ffffff;
  text-decoration: none;
  display: block;
  width: max-content;
  font-family: $font-family;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
  font-weight: $weight-bold;
  padding: 17.5px calc(40px + 1rem) 15px 20px; // 17.5 MAGIC NUMBER
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin: 30px 0;
  -webkit-tap-highlight-color: transparent;

  &--dark {
    color: #000000;

    .standard-button__plus > div {
      background-color: #000000;
    }

    &::before {
      background-color: rgba(133, 109, 86, 0.6) !important;
    }
  }

  &--white {
    &::before {
      background-color: rgba(255, 255, 255, 0.3) !important;
    }
  }
}

.standard-button__plus {
  height: calc(30px + 1rem);
  width: calc(30px + 1rem);
  position: absolute;
  right: 0;
  top: 0;
}

.standard-button__plus > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center right;
  background-color: #ffffff;
  height: 1.5px;
  border-radius: 0.75px;
  width: 15%;
}

.standard-button__plus > div:first-child {
  transform: translate(-50%, -50%) rotate(45deg);
}

.standard-button__plus > div:last-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.standard-button::before {
  display: block;
  content: '';
  height: calc(30px + 1rem);
  border-radius: calc((30px + 1rem) / 2);
  z-index: -1;
  background-color: rgba(133, 109, 86, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: calc(100% - (30px + 1rem));
  transition: right 200ms ease-in-out 400ms, left 400ms ease;
}

.standard-button:hover::before {
  left: calc(100% - (30px + 1rem));
  right: 0;
  transition: left 200ms ease-in-out 400ms, right 400ms ease;
}
