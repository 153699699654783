footer {
  position: relative;
  z-index: $z-index-footer;
  background-repeat: no-repeat;
  background-size: 90vw;
  background-position: 0 50%;
  background-color: $dark-grey;
  background-blend-mode: normal;
  font-size: 0.9rem;
  color: $text-grey;

  // Versione desktop
  @include respond-to('medium') {
    background-size: 50vw;
  }

  strong {
    font-weight: $weight-bold;
  }

  .footer__content {
    @include standard-dimensions;
    padding-bottom: 2em !important;
    .footer__contacts {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2em;
      align-items: center;

      // &--operative {
      //   border-left: 1px solid $text-grey;
      //   padding-left: 1em;
      // }

      // &--legal {
      //   border-left: 1px solid #fff;
      //   padding-left: 1em;
      // }

      .contacts--title {
        font-weight: $weight-bold;
        font-size: 1rem;
      }

      .contacts__info {
        display: flex;
        gap: 1em;
      }

      .footer__social {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .social-icon:hover {
          filter: invert(50%);
        }
      }
    }

    .footer__badges {
      display: flex;
      justify-content: end;
      gap: 2rem;
      margin-bottom: 2rem;

      img {
        width: auto;
        height: 4.7rem;
      }
    }

    .footer__review {
      display: flex;
      justify-content: end;
      border-bottom: 1px solid $text-grey;
      padding-bottom: 0.5em;
      align-items: center;
    }

    .footer__info {
      display: flex;
      justify-content: space-between;
      padding-top: 0.5em;

      .footer__info--agency {
        width: 50%;
      }
    }
  }

  .footer__subtitle {
    font-size: 0.8rem;
    font-weight: $weight-bold;
    color: $text-grey;
    text-decoration: none;
    text-transform: uppercase;
  }

  .footer__text {
    font-weight: $weight-light;
    font-size: 0.8rem;
    text-decoration: none;
    color: $text-grey;
  }

  .turc {
    cursor: url('/img/turc.png'), auto;
  }

  .text-right {
    text-align: right;
  }
}

.footer__logo {
  display: block;
  text-align: center;
  grid-area: logo;
  &--desktop {
    display: none;
  }

  // Versione desktop
  @include respond-to('medium') {
    width: auto;
    height: 50px;
    margin-right: 0;
    margin-bottom: 0;

    &--mobile {
      display: none;
    }

    &--desktop {
      display: block;
    }
  }
}

@include respond-to('max-large') {
  .footer__contacts {
    flex-direction: column;
    align-items: center;
    gap: 2em;

    &--operative {
      text-align: center;
      border-left: none !important;
      padding-left: 0 !important;
    }

    &--legal {
      text-align: center;
      border-left: none !important;
      padding-left: 0 !important;
    }

    .footer__social {
      flex-direction: row !important;
      gap: 1.5rem !important;
    }
  }

  .footer__badges {
    justify-content: center !important;
    flex-wrap: wrap !important;
    gap: 1rem !important;
    img {
      width: auto !important;
      height: 4rem !important;
    }
  }

  .footer__info--policy {
    .footer__text {
      text-align: right;
    }
  }
}

@include respond-to('max-small') {
  .footer__review {
    justify-content: center !important;
  }

  .footer__info {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    .footer__info--agency {
      text-align: center;
      width: 80% !important;
    }
    .footer__info--policy {
      .footer__text {
        text-align: center !important;
      }
    }
  }

  .footer__contacts--logo {
    justify-content: center;
    display: flex;
  }

  .footer__logo {
    width: 60%;
  }
}
