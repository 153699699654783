.slide-in,
.slide-in-alt {
  opacity: 0;
  transition: all 800ms ease;

  &.from-left {
    transform: translateX(-30%);

    &::before {
      transform: translateX(20%);
    }

    img {
      transform: translateX(50%);
    }
  }

  &.from-right {
    transform: translateX(30%);

    &::before {
      transform: translateX(-20%);
    }

    img {
      transform: translateX(-50%);
    }
  }

  &.from-top {
    transform: translateY(-50%) rotate(-2deg);

    &::before {
      transform: translateY(20%);
    }

    img {
      transform: translateY(50%);
    }
  }

  &.from-bottom {
    transform: translateY(50%) rotate(2deg);

    &::before {
      transform: translateY(-20%);
    }

    img {
      transform: translateY(-50%);
    }
  }

  &.visible {
    opacity: 1;
    transform: none;
  }

  // Versione con immagine
  img {
    transition: transform 700ms ease;
  }

  &.visible {
    img {
      transform: none;
    }
  }
}

// Test animation
.slide-in-load,
.slide-in-load-alt {
  opacity: 0;

  &.animate {
    opacity: 1;
    animation: introduction-image 2s ease;
    animation-fill-mode: forwards;
    transform: scale(1);
    clip-path: inset(0 0 0 -100%);
  }
}

@keyframes introduction-image {
  0% {
    transform: scale(1);
    clip-path: inset(0 0 0 100%);
  }
  70% {
    clip-path: inset(0);
  }
  100% {
    opacity: 1;
    clip-path: inset(0);
  }
}
