// @font-face {
//   font-family: 'Plain';
//   src: url('../fonts/Plain-Bold.eot');
//   src: url('../fonts/Plain-Bold.eot?#iefix') format('embedded-opentype'),
//     url('../fonts/Plain-Bold.woff2') format('woff2'),
//     url('../fonts/Plain-Bold.woff') format('woff'),
//     url('../fonts/Plain-Bold.ttf') format('truetype'),
//     url('../fonts/Plain-Bold.svg#Plain-Bold') format('svg');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Plain';
//   src: url('../fonts/Plain-Light.eot');
//   src: url('../fonts/Plain-Light.eot?#iefix') format('embedded-opentype'),
//     url('../fonts/Plain-Light.woff2') format('woff2'),
//     url('../fonts/Plain-Light.woff') format('woff'),
//     url('../fonts/Plain-Light.ttf') format('truetype'),
//     url('../fonts/Plain-Light.svg#Plain-Light') format('svg');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Plain';
//   src: url('../fonts/Plain-Thin.eot');
//   src: url('../fonts/Plain-Thin.eot?#iefix') format('embedded-opentype'),
//     url('../fonts/Plain-Thin.woff2') format('woff2'),
//     url('../fonts/Plain-Thin.woff') format('woff'),
//     url('../fonts/Plain-Thin.ttf') format('truetype'),
//     url('../fonts/Plain-Thin.svg#Plain-Thin') format('svg');
//   font-weight: 100;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-Black.ttf') format('truetype');
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-BlackItalic.ttf') format('truetype');
//   font-weight: 900;
//   font-style: italic;
//   font-display: swap;
// }
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/poppins-extrabold-webfont.woff2') format('woff2'),
    url('../fonts/Poppins/poppins-extrabold-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
// @font-face {
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
//   font-weight: 800;
//   font-style: italic;
//   font-display: swap;
// }
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/poppins-bold-webfont.woff2') format('woff2'),
    url('../fonts/Poppins/poppins-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
// @font-face {
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-BoldItalic.ttf') format('truetype');
//   font-weight: 700;
//   font-style: italic;
//   font-display: swap;
// }
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/poppins-semibold-webfont.woff2') format('woff2'),
    url('../fonts/Poppins/poppins-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
// @font-face {
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
//   font-weight: 600;
//   font-style: italic;
//   font-display: swap;
// }
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/poppins-medium-webfont.woff2') format('woff2'),
    url('../fonts/Poppins/poppins-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
// @font-face {
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-MediumItalic.ttf') format('truetype');
//   font-weight: 500;
//   font-style: italic;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-Regular.ttf') format('truetype');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-Italic.ttf') format('truetype');
//   font-weight: 400;
//   font-style: italic;
//   font-display: swap;
// }
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/poppins-light-webfont.woff2') format('woff2'),
    url('../fonts/Poppins/poppins-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
// @font-face {
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-LightItalic.ttf') format('truetype');
//   font-weight: 300;
//   font-style: italic;
//   font-display: swap;
// }
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/poppins-extralight-webfont.woff2') format('woff2'),
    url('../fonts/Poppins/poppins-extralight-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
// @font-face {
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
//   font-weight: 200;
//   font-style: italic;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-Thin.ttf') format('truetype');
//   font-weight: 100;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-ThinItalic.ttf') format('truetype');
//   font-weight: 100;
//   font-style: italic;
//   font-display: swap;
// }

// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:700&display=swap');
