@use 'sass:math';

.standard-section-new {
  @include standard-dimensions;

  &__title {
    display: block;
    text-transform: uppercase;
    font-weight: $weight-extraLight;
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: 0.1rem;
    margin: 0;
    padding: 0;

    @include respond-to('medium') {
      font-size: 3rem;
      line-height: 3.5rem;
      letter-spacing: 0.15rem;
    }

    &--alt {
      display: block;
      color: $secondary-color;
      font-weight: $weight-bold;
      letter-spacing: 0.3rem;
      line-height: 1.4rem;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-size: initial;

      &.animate-in {
        // Initial animation status
        visibility: hidden;
      }

      @include respond-to('medium') {
        transform: translateX(-25px);
      }
    }
  }

  &__subtitle {
    display: block;
    font-weight: $weight-bold;
    font-size: 2.5rem;
    line-height: 2rem;
    letter-spacing: 0.1rem;
    position: relative;
    text-transform: uppercase;
    padding: 0;
    margin-bottom: 35px;

    @include respond-to('medium') {
      transform: translateX(-3rem);
      font-size: 3.5rem;
      line-height: 3rem;
    }

    &--alt {
      display: block;
      font-weight: $weight-extraBold;
      text-transform: uppercase;
      font-size: 2.5rem;
      line-height: 2.5rem;
      letter-spacing: 0.1rem;
      margin-bottom: 4rem;

      &.animate-in {
        // Initial animation status
        visibility: hidden;
      }

      @include respond-to('medium') {
        @include respond-to('portrait') {
          font-size: 2.5rem;
          line-height: 3.5rem;
        }

        @include respond-to('landscape') {
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }

      @include respond-to('extra-large') {
        font-size: 3rem;
        line-height: 3.5rem;
      }

      @include respond-to('huge') {
        font-size: 4.2rem;
        line-height: 5.5rem;
      }
    }
  }
}

.standard-section-new__text {
  padding-bottom: 2em;
}
