// =============================================================================
// Colors
// =============================================================================

// COLORS
$dark-grey: #191919;
$medium-grey: #242424;
$light-grey: #606060;
$dark-white: #f4f4f4;

$secondary-color: #856d56;

$text-grey: #dddddd;
$text-main: $text-grey;

$button-over-back: $dark-grey;
// END COLORS

// =============================================================================
// Breakpoints
// =============================================================================

$breakpoints: (
  'small': (
    // Extra small devices (portrait phones, greater than 576px)
    min-width: 576px,
  ),
  'medium': (
    // Small devices (landscape phones, greater than 768px)
    min-width: 768px,
  ),
  'large': (
    // Medium devices (tablets, greater than 992px)
    min-width: 992px,
  ),
  'extra-large': (
    // Large devices (desktops, greater than 1200px)
    min-width: 1200px,
  ),
  'huge': (
    // Huge devices (huge desktop, greater than 1600px)
    min-width: 1600px,
  ),
  'max-small': (
    // Extra small devices (portrait phones, less than 576px)
    max-width: 768px,
  ),
  'max-medium': (
    // Small devices (landscape phones, less than 768px)
    max-width: 992px,
  ),
  'max-large': (
    // Medium devices (tablets, less than 992px)
    max-width: 1200px,
  ),
  'max-extra-large': (
    // Large devices (desktops, less than 1200px)
    max-width: 1600px,
  ),
  'landscape': (
    // Landscape oriented
    orientation: landscape,
  ),
  'portrait': (
    // Potrat oriented
    orientation: portrait,
  ),
);

// Huge desktop: no max breakpoint required

// @media (max-width: $breakpoint-mobile) {...}

// =============================================================================
// Fonts
// =============================================================================

$font-family: 'Poppins', 'Plain', 'Montserrat', 'Helvetica', 'Arial', sans-serif;

// $weight-black: 800;
$weight-extraBold: 800;
$weight-bold: 700;
$weight-semiBold: 600;
$weight-medium: 500;
// $weight-regular: 400;
$weight-light: 300;
$weight-extraLight: 200;
// $weight-thin: 100;

$size-base: 15px;
$size-large: 15px;

$size-h1: 5rem;
$size-h3: 1.9rem;
$size-h4: 1.7rem;

$size-h3-desktop: 2.5rem;

$size-button: 1.2rem;

$standard-line-height: 1.5rem;

$line-height-h3: 2.8rem;

// =============================================================================
// Margins & Paddings
// =============================================================================

$vertical-padding: 45px;
$horizontal-padding: 23px;

$medium-vertical-padding: 50px;
$medium-horizontal-padding: 70px;

$large-vertical-padding: 90px;
$large-horizontal-padding: 90px;

$extra-large-vertical-padding: 110px;
$extra-large-horizontal-padding: 140px;

$button-padding-vertical: 0.7em;
$button-padding-horizontal: 1.5em;
$button-padding: $button-padding-vertical $button-padding-horizontal;

// =============================================================================
// Widths & Heights
// =============================================================================

$standard-max-width: 1800px;

// =============================================================================
// Z indexes
// =============================================================================

$z-index-header: 10;
$z-index-menu: 9;
$z-index-navigator: 8;
$z-index-hero: 2;
$z-index-container: 3;
$z-index-footer: 3;

// =============================================================================
// Others
// =============================================================================

$scrollbar-thickness: 7px;
