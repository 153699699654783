@use 'sass:math';

// Stile generale header
header {
  position: fixed;
  z-index: $z-index-header;
  width: 100%;
  padding: $horizontal-padding;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  pointer-events: none;

  transform: translateY(-100%);

  // Stile logo
  .logo {
    width: 40%;
    max-width: 160px;
    clip-path: inset(0);
    transition: clip-path 250ms ease-in-out;
    pointer-events: auto;

    .aa {
      filter: invert(100%);
    }

    &.small {
      clip-path: inset(0 78% 0 0);
    }

    img {
      width: 100%;
    }
  }
}

body.dark-logo:not(.menu-opened),
body.menu-opened:not(.dark-logo) {
  header .logo img {
    filter: invert(100%);
  }

  .navigation-container {
    nav > ul > li > a {
      color: #000000 !important;

      &::after {
        background-color: #000000 !important;
      }
    }
  }
}

.navigation-container {
  display: flex;
  align-items: center;

  nav {
    margin-right: 2em;
    display: none;

    @include respond-to('large') {
      &.visible {
        display: block;
      }
    }

    ul {
      display: flex;
      align-items: center;

      li {
        display: block;

        a {
          display: block;
          color: #ffffff;
          text-decoration: none;
          padding: 1em;
          font-weight: $weight-extraLight;
          pointer-events: auto;
          position: relative;

          &::after {
            position: absolute;
            display: block;
            content: '';
            top: 100%;
            left: 0;
            width: 0;
            height: 1.5px;
            border-radius: 1px;
            background-color: #fff;
            transition: width 150ms ease;
          }

          &.inactive {
            pointer-events: none;
          }

          &:hover {
            // color: $secondary-color;
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

$hamburger-container-width: 2.4rem;
$hamburger-container-expanded-width: 6.8rem;

.hamburger-container {
  position: relative;
  border-radius: 1000vh;
  height: $hamburger-container-width;
  width: $hamburger-container-expanded-width;

  .hamburger__background {
    background-color: rgba(0, 0, 0, 0.5);
    width: $hamburger-container-width;
    height: $hamburger-container-width;
    border-radius: 1.2rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    transition: width 150ms ease, transform 200ms ease;
  }

  &.visible-text {
    @include respond-to('medium') {
      transition: padding 100ms linear;

      .hamburger__text {
        opacity: 1;
        transition: opacity 150ms linear;
      }

      .hamburger__background {
        width: $hamburger-container-expanded-width;
      }
    }
  }
}

.hamburger__text {
  display: none;
  position: absolute;
  left: 1.3rem;
  top: 50%;
  transform: translateY(-50%);
  font-weight: $weight-extraLight;
  opacity: 0;
  transition: opacity 150ms linear;

  @include respond-to('medium') {
    display: block;
  }
}

$hamburger-height: 0.8rem;
$hamburger-width: 1.2rem;

.hamburger {
  height: $hamburger-height;
  width: $hamburger-width;
  position: absolute;
  top: math.div($hamburger-container-width - $hamburger-height, 2);
  right: math.div($hamburger-container-width - $hamburger-width, 2);
  cursor: pointer;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: transform 150ms ease;

  &:hover {
    @include respond-to('medium') {
      transform: rotate(90deg);
    }
  }

  // Aumenta l'area di click
  &::after {
    content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }

  // Stile della linea
  &__line {
    position: absolute;
    top: calc(50% - 1px);
    height: 2px;
    border-radius: 1px;
    width: 100%;
    background-color: #ffffff;

    &:first-child {
      transform: translateY(calc($hamburger-height / -2));
    }

    &:last-child {
      transform: translateY(calc($hamburger-height / 2));
    }
  }
}
