@use 'sass:math';

html {
  overflow-x: hidden;
  //scroll-behavior: smooth; TEMP: rompe smooth scroll
}

body {
  font-size: $size-base;
  font-family: $font-family;
  background-color: $dark-grey;
  color: $text-main;
  overflow-x: hidden; //TEMP non va position sticky con overflow-x attivo
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.full-height {
    height: 100vh;

    main {
      position: relative;
      max-height: 100%;
    }
  }

  @include respond-to('large') {
    font-size: $size-large;
  }

  @include respond-to('max-medium') {
    -webkit-text-size-adjust: 100%;
  }
}

::-webkit-scrollbar {
  height: $scrollbar-thickness;
  width: $scrollbar-thickness;
}

::-webkit-scrollbar-track {
  background: $dark-grey;
  // border-radius: calc($scrollbar-thickness / 2);
}

::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: calc($scrollbar-thickness / 2);
}

::-webkit-scrollbar-thumb:hover {
  background: $dark-white;
}

.noscript-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: $dark-grey;
  color: #ffffff;
  font-size: 2rem;
  display: grid;
  place-items: center;
}

main {
  padding-left: $horizontal-padding;
  padding-right: $horizontal-padding;
  user-select: none;
}

h1,
h2 {
  text-transform: uppercase;
}

h3 {
  font-size: $size-h3;
  line-height: $line-height-h3;
  font-weight: $weight-bold;
  margin-bottom: 1em;

  @include respond-to('medium') {
    font-size: $size-h3-desktop;
  }
}

h4 {
  font-size: $size-h4;
  font-weight: $weight-bold;
  margin-bottom: 0.5em;
}

p {
  line-height: $standard-line-height;
  font-weight: $weight-extraLight;
}

strong {
  font-weight: $weight-bold;
}

@include respond-to('max-small') {
  .medium-only {
    display: none !important;
  }
}

@include respond-to('max-medium') {
  .desktop-only {
    display: none !important;
  }
}

@include respond-to('medium') {
  .mobile-only {
    display: none !important;
  }
}

// Immagini in tag picture
picture > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.turc-img {
  display: block;
  position: fixed;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 9999;
  animation: 30s ease-in-out turc;
  animation-fill-mode: forwards;
  pointer-events: none;
}

@keyframes turc {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(25);
    opacity: 1;
  }
  100% {
    transform: scale(0) rotate(800deg);
    opacity: 0;
  }
}

#preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  // transform-origin: center bottom;
  // transform: scaleY(0);
  // background-color: $dark-grey;
  display: none;
  backdrop-filter: blur(0);
  background-color: rgba($dark-grey, 0);

  &.closing {
    display: block;
  }
}

// Copied from servizi.scss
$larghezza-card: math.div(100%, 3);
$larghezza-card-medium: math.div(100%, 2.5);

#services-preloader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  display: none;

  &.closing {
    display: flex;

    div {
      transform-origin: 0% 0%;
    }
  }

  div {
    transform: scaleX(0);
    height: 100%;
    background-color: $dark-grey;
    transform-origin: center right;
    // box-shadow: 0px 0px 10px 5px #0000004d;

    // Disposizione orizzontale in desktop
    @include respond-to('medium') {
      @include respond-to('landscape') {
        height: 100%;
        flex-basis: $larghezza-card-medium;
      }
    }

    @include respond-to('extra-large') {
      flex-basis: $larghezza-card;
    }
  }
}
