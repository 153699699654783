@use 'sass:math';

@import '0-helpers/0-helpers';
@import '1-base/1-base';
@import '2-layout/2-layout';
@import '3-modules/standard-button';
@import '3-modules/standard-section-new';
@import '3-modules/cta';
@import '3-modules/image-slider';
@import '3-modules/animations';

main {
  padding: 0;
  color: $text-grey;
}

.intro {
  min-height: 100vh;
  min-height: 100vh;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-service-button {
  visibility: hidden;
}

.home-paragraph-container {
  display: none;
  font-size: 0.9rem;
  margin-top: 2.5em;

  @include respond-to('medium') {
    display: flex;
  }

  & > p {
    visibility: hidden;
    transform-origin: top left;

    font-size: 15px;
    max-width: 35em;

    &:first-child {
      margin-right: 3em;
    }

    &:last-child {
      max-width: 33em;
    }
  }
}

$title-size-mob: 1.6rem;
$title-size-desk: 2rem;
$title-size-huge: 2.5rem;

// Projects
.projects {
  background-color: #ffffff;

  .projects__section {
    color: #000000;
    padding-bottom: 0px !important;
  }

  .projects__tab {
    @include respond-to('small') {
      @include standard-dimensions;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    .project__tabs {
      display: flex;
      gap: 1.3em;
      cursor: pointer;
      // padding: 1.5em 0;
      &::-webkit-scrollbar {
        display: none;
      }

      @include respond-to('max-small') {
        white-space: nowrap;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        .project-tab:first-child {
          padding-left: 1.625em;
        }
        .project-tab:last-child {
          padding-right: 1.625em;
        }
      }
      .project-tab {
        text-transform: uppercase;
        border-left: 2px solid $text-grey;
        color: $text-grey;
        padding-left: 1.3em;
        font-weight: $weight-bold;
        user-select: none;
        &.active {
          color: #000000;
          user-select: none;
        }
      }
    }
    .project-tab:first-child {
      border-left: none;
      padding-left: 0;
    }
  }

  .projects__category__slider {
    @include respond-to('small') {
      @include standard-dimensions;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }

  .slider-active {
    transform: translateY(2rem);
  }
}

// About
.about {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 30px 0;
  h3 {
    margin-bottom: 1.3rem !important;
  }

  .parallax-title {
    position: absolute;
    right: 12rem;
    bottom: 0;
    z-index: 5;
    transform: rotate(180deg);

    @include respond-to('max-medium') {
      right: 1.5rem;
      bottom: 9rem;
    }

    .vertical-right-title {
      font-size: 10rem;
      font-weight: $weight-extraBold;
      color: rgba(255, 255, 255, 0.1);
      writing-mode: vertical-lr;
      text-orientation: mixed;
      user-select: none;
      pointer-events: none;

      @include respond-to('max-medium') {
        font-size: 11rem;
        font-weight: $weight-bold;
      }

      @include respond-to('max-small') {
        font-size: 8rem;
      }
    }
  }

  .btn-about {
    margin-bottom: 3rem;
  }

  .about__content {
    width: 100%;
    display: flex;
    align-items: center;

    .about__content__info {
      z-index: 2;

      .standard-section__title {
        text-transform: uppercase;
      }

      .standard-section__text {
        padding-top: 0.7em;

        @include respond-to('max-medium') {
          padding-top: 0;
        }
      }

      .cross__container__mobile {
        @include respond-to('medium') {
          display: none;
        }

        .cross-section {
          margin: 0 auto;
          width: 100%;
          max-width: 350px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr;

          .cross-section-item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 0.5em;
            text-align: center;
            font-size: 2rem;
            font-weight: $weight-bold;
            border: 1px solid rgba(255, 255, 255, 0.2);
            padding: 0.5em;

            .cross-section-number {
              font-weight: $weight-extraBold;
              color: $secondary-color;
              font-size: 3rem;
            }

            .cross-section-description {
              font-weight: $weight-bold;
              font-size: 1rem;
              word-wrap: break-word;
              text-transform: uppercase;
            }
          }

          .quadrante1 {
            border-top: none;
            border-left: none;
          }

          .quadrante2 {
            border-top: none;
            border-right: none;
          }

          .quadrante3 {
            border-bottom: none;
            border-left: none;
          }

          .quadrante4 {
            border-bottom: none;
            border-right: none;
          }
        }
      }

      .cross__container__full {
        @include respond-to('max-small') {
          display: none;
        }

        .cross-section {
          display: flex;

          .cross-section-item {
            font-size: 2rem;
            font-weight: $weight-bold;
            padding: 0.5em;
            gap: 1em;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            border-right: 2px solid rgba(255, 255, 255, 0.2);
            width: 200px;

            .cross-section-number {
              font-weight: $weight-extraBold;
              color: $secondary-color;
              font-size: 3rem;
            }

            .cross-section-description {
              font-weight: $weight-bold;
              font-size: 1.1rem;
              word-wrap: break-word;
              text-transform: uppercase;
            }
          }

          .cross-section-item:last-child {
            border-right: none;
          }
        }
      }

      &__container {
        position: relative;
        z-index: 1;
        max-width: 50%;

        @include respond-to('max-medium') {
          max-width: 100%;
        }
        .home-text {
          padding-bottom: 2em;
        }
      }

      .about__content__img__mobile {
        display: none;

        @include respond-to('max-medium') {
          display: block;
          margin-top: 4em;
          background-color: #191919;
        }

        img {
          mix-blend-mode: lighten;
          width: 100%;
          height: auto;
        }
      }
    }

    .about__content__img {
      position: absolute;
      right: -10rem;
      top: 0rem;
      max-width: 70%;
      height: max-content;
      display: flex;
      align-items: flex-end;
      overflow-y: hidden;

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 27%;
        background: linear-gradient(
          180deg,
          rgba($dark-grey, 0) 0%,
          $dark-grey 80%
        );
      }

      @include respond-to('max-medium') {
        display: none;
      }

      &__container {
        max-width: 100%;
        height: auto;
        overflow-y: hidden;

        &:last-child {
          position: absolute;
          bottom: 0;
          left: 0;
        }

        &:first-child {
          position: relative;
          background-color: $dark-grey;

          picture {
            mix-blend-mode: lighten;
          }
        }
      }

      picture {
        display: block;
        width: 100%;
        height: 100%;

        img {
          object-position: bottom;
        }
      }
    }
  }
}

// Services
.services {
  position: relative;
  .services-background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    object-fit: cover !important;
    object-position: center center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .parallax-title {
    position: absolute;
    left: 10rem;
    bottom: 25rem;
    z-index: 1;
    transform: rotate(180deg);

    @include respond-to('max-medium') {
      left: 1rem;
      bottom: 2rem;
    }

    @include respond-to('max-small') {
      left: 3rem;
      bottom: 17rem;
    }

    .vertical-left-title {
      font-size: 10rem;
      font-weight: $weight-extraBold;
      color: rgba(255, 255, 255, 0.1);
      writing-mode: vertical-lr;
      pointer-events: none;

      @include respond-to('max-medium') {
        font-size: 9rem;
        font-weight: $weight-bold;
      }

      @include respond-to('max-small') {
        font-size: 8rem;
      }
    }
  }

  .services__content {
    @include standard-dimensions;
    padding-top: 13em !important;
    padding-bottom: 13em !important;
    @include respond-to('max-small') {
      padding-top: 5em !important;
      padding-bottom: 5em !important;
    }
    .services__content__description {
      padding-bottom: 5em;
      width: 45%;

      @include respond-to('max-small') {
        width: 100%;
      }
    }

    .services__content__ourservices {
      display: flex;
      justify-content: end;

      @include respond-to('max-medium') {
        justify-content: start;
        flex-direction: row-reverse;
      }

      .border-service {
        background-color: $text-grey;
        width: 2px;
        padding: 1px;
        transform-origin: top center;
        transform: scaleY(1);
      }

      .services__content__ourservices__list {
        list-style: none;
        padding-right: 20px;
        text-align: right;

        @include respond-to('max-medium') {
          text-align: left;
          padding-right: 0;
          padding-left: 20px;
        }

        li {
          padding: 1em 0;
          transform-origin: right center;
          transition: transform 300ms ease;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;
          }

          a {
            font-size: $title-size-mob;
            text-decoration: none;
            color: $text-grey;
            text-transform: uppercase;
            font-size: $title-size-mob;

            h3 {
              margin-bottom: 0;
              line-height: 2rem;
              font-weight: $weight-extraLight;
            }

            // Font più grande in desktop
            @include respond-to('medium') {
              font-size: $title-size-desk;
            }

            @include respond-to('huge') {
              font-size: $title-size-huge;
            }

            strong {
              font-weight: $weight-bold;
            }
          }
        }

        li:not(.disabled):hover {
          transform: translateX(-4%);
          @include respond-to('max-medium') {
            transform: translateX(4%);
          }
          a {
            strong {
              color: $secondary-color;
            }
          }
        }
      }
    }
  }
}
.services::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    190deg,
    rgba(25, 25, 25, 1) 20%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

// Clients
.clients {
  background-color: #fff;

  .clients__content {
    color: #000;

    .home-text {
      width: 45%;
      margin-bottom: 2em;

      @include respond-to('max-medium') {
        width: 100%;
      }
    }
  }

  .clients__content__ourclients {
    .grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px 20px;
      margin: 0 auto;

      img {
        width: 100%;
        filter: grayscale(100%) contrast(0) brightness(0);
        transition: transform 120ms linear;

        &:hover {
          transform: scale(1.1);
        }
      }

      @include respond-to('medium') {
        grid-template-columns: repeat(5, 1fr);
        gap: 10px 10px;
        padding: 0;
      }

      @include respond-to('large') {
        gap: 20px 20px;
      }
    }
  }
}

// Tecnologies
.technologies {
  background-color: $dark-grey;
  position: relative;

  .parallax-title {
    position: absolute;
    right: 12rem;
    bottom: 61rem;
    transform: rotate(180deg);
    z-index: 1;

    @include respond-to('max-medium') {
      right: 1.5rem;
      bottom: 9rem;
    }

    @include respond-to('max-small') {
      bottom: 70rem;
    }

    .vertical-right-title {
      font-size: 10rem;
      font-weight: $weight-extraBold;
      color: rgba(255, 255, 255, 0.1);
      writing-mode: vertical-lr;
      text-orientation: mixed;
      user-select: none;
      pointer-events: none;

      @include respond-to('max-medium') {
        font-size: 9rem;
        font-weight: $weight-bold;
      }
    }
  }

  .technologies__content {
    color: $text-grey;

    .home-text {
      width: 45%;
      padding-bottom: 3em;
      @include respond-to('max-medium') {
        width: 100%;
        height: auto;
      }
    }
  }

  .technologies__content__ourtechnologies {
    .grid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      img {
        background-color: rgba(255, 255, 255, 0.02);
        width: 100%;
      }

      @include respond-to('medium') {
        grid-template-columns: repeat(6, 1fr);
        gap: 20px;
        padding: 0;
        width: 75%;

        img {
          width: 100%;
          background-color: rgba(255, 255, 255, 0.02);
        }
      }
    }
  }
}
