@use 'sass:math';

@import '../3-modules/standard-button';

.cta {
  background: $secondary-color;
  color: $text-grey;

  .secondary-button {
    display: block;
    width: max-content;
    margin-bottom: 0;
  }
  .secondary-button::before {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &--with-form {
    .cta__content {
      display: flex;
      flex-direction: column;

      @include respond-to('medium') {
        flex-direction: row;
      }
    }
  }
}

.cta-contact-form {
  .hide {
    display: none;
  }
}

.cta__content {
  @include standard-dimensions;

  @include respond-to('medium') {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  @include respond-to('extra-large') {
    padding-top: 170px;
    padding-bottom: 170px;
  }
}

.cta__contact {
  display: flex;
  gap: 10em;
  user-select: text;
  @include respond-to('max-small') {
    gap: 1em;
  }

  .cta__contact__title {
    color: $text-grey;
    opacity: 0.4;
    font-weight: $weight-bold;
    font-size: 1.2rem;
    line-height: 3rem;
    text-transform: uppercase;

    @include respond-to('max-small') {
      font-size: 1rem;
      line-height: 2rem;
    }
  }

  .cta__contact__subtitle {
    color: $text-grey;
    font-weight: $weight-light;
    text-decoration: none;
    &:hover {
      opacity: 0.7;
    }
  }
}

.cta__info {
  width: 50%;
  border-right: 2px solid #ddd;
  padding-right: 5em;
  @include respond-to('max-medium') {
    border: none;
    width: 100%;
    padding-right: 0;
  }
}

.cta__subtitle {
  font-weight: $weight-light;
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #d2d2d2;
  @include respond-to('medium') {
    font-size: 1.2rem;
    margin-bottom: 54px;
  }
}

.cta__text {
  font-weight: $weight-bold;
  font-size: 3.5rem;
  line-height: 4rem;
  margin-bottom: 50px;
  font-weight: $weight-bold;
  text-transform: uppercase;

  @include respond-to('small') {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }

  @include respond-to('medium') {
    line-height: 5.5rem;
    margin-bottom: 54px;
  }

  @include respond-to('large') {
    font-size: 5.4rem;
    line-height: 6rem;
  }
}

.cta__text__info {
  font-size: 3.5rem;
  line-height: 4.5rem;
  margin-bottom: 50px;
  font-weight: $weight-extraBold;
  text-transform: uppercase;

  @include respond-to('max-medium') {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  @include respond-to('max-small') {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}

.cta__form {
  visibility: hidden;
  flex-basis: 50%;
  padding-left: 5em;

  @include respond-to('max-medium') {
    padding-left: 0;
    padding-top: 3em;
  }

  &__title {
    display: block;
    font-size: 1.6rem;
    letter-spacing: 0.3rem;
    margin-bottom: 1em;
    text-transform: uppercase;
    font-weight: $weight-bold;

    @include respond-to('max-small') {
      font-size: 1.8rem;
      margin-bottom: 0.5em;
    }
  }

  .input-group {
    width: 100%;
    margin-bottom: 1.5em;
    position: relative;

    &__input {
      all: unset;
      background: transparent;
      border: none;
      display: block;
      width: 100%;
      padding: 0.5em 0;
      color: $text-grey;
      font-family: 'Poppins', 'Arial', sans-serif;
      font-size: 1rem;
      position: relative;
    }

    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 100%;
      width: 100%;
      height: 1.5px;
      border-radius: 2px;
      background-color: #ffffff;
      transition: all 400ms ease-in-out;
    }

    &.error {
      &::after {
        background-color: $secondary-color;
      }

      .input-error {
        display: block;
      }
    }
  }

  .input-group__label {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% - 2px));
    color: $text-grey;
    font-family: 'Poppins', 'Arial', sans-serif;
    font-size: 1.1rem;
    font-weight: $weight-extraLight;
    pointer-events: none;
    user-select: none;
    transition: all 250ms ease-in-out;
  }

  .input-group__icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 1.3rem;
    cursor: pointer;
    filter: invert(100%);
    transition: opacity 200ms linear, transform 250ms ease, opacity 250ms linear;
    opacity: 0;
    transform: translate(50%, -50%);
    pointer-events: none;

    img {
      height: 100%;
    }
  }

  .input-error {
    display: none;
    position: absolute;
    bottom: 80%;
    font-size: 1rem;
    font-weight: $weight-bold;
    color: #000000;
  }

  #form-error {
    margin-top: 2em;
  }

  .form-error__link {
    color: $text-grey;
    font-weight: $weight-bold;
    text-decoration: none;
    cursor: pointer;
    transition: opacity 200ms linear;

    &:hover {
      opacity: 0.7;
    }
  }

  // Remove input x in edge
  ::-ms-clear {
    display: none;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: $text-grey;
    -webkit-box-shadow: none;
    box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }

  // Custom textarea
  .custom-textarea {
    all: unset;
    display: block;
    resize: none;
    width: 90%;
    background: transparent;
    color: $text-grey;
    border: none;
    font-size: 1rem;
    padding: 0.5em 0;
    font-family: 'Poppins', 'Arial', sans-serif;
    height: 1.2rem;
    overflow-y: hidden;
    transition: height 300ms ease;
  }

  .custom-textarea.active {
    height: 4.8rem;
  }

  // Custom checkbox
  $custom-checkbox-size: 22px;
  .custom-checkbox {
    display: none;

    & + label {
      cursor: pointer;
      display: block;
      position: relative;
      padding-left: 30px;
      user-select: none;

      &::before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: calc(50% - #{math.div($custom-checkbox-size, 2)});
        height: $custom-checkbox-size;
        width: $custom-checkbox-size;
        border: 1px solid $text-grey;
        border-radius: 3px;
      }
    }

    &:checked + label::before {
      background-color: $dark-white;
    }
  }

  .form-info {
    font-size: 0.6rem;
    font-weight: $weight-light;
    width: 100%;
    margin: 2em 0 3em 0;
    line-height: 1rem;
  }

  .privacy-input-group {
    width: 100%;
    font-size: 0.9rem;

    &.error {
      .input-error {
        display: block;
      }
    }

    .input-error {
      bottom: 150%;
    }

    @include respond-to('huge') {
      bottom: 50px;
    }
  }

  .form-container__privacy {
    font-weight: $weight-light;
    font-size: 0.7rem;
    a {
      text-decoration: none;
      font-weight: $weight-bold;
      color: $text-grey;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .form-sent {
    width: 100%;
    font-weight: $weight-bold;
    font-size: 1.3rem;
    line-height: 1.5rem;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms linear 200ms;
    display: none;

    &.visible {
      display: block;
      opacity: 1;
    }
  }

  .form-sent__smile {
    display: block;
    width: 15%;
    margin: 50px auto;
  }
}
