// Stile del menù
.menu {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: $z-index-menu;
  top: 0;
  visibility: hidden;

  // Variazione chiara
  &__back {
    &--left {
      background-color: #ffffff;
    }

    &--right {
      background-color: $dark-white;
    }
  }

  &__container {
    nav ul li a {
      color: #ffffff;
      background-color: #000000;
    }

    .menu__info ul li,
    .menu__info ul li a {
      color: #000000;
    }

    .menu__social span {
      color: #000000;
    }

    .social-icon {
      filter: invert(100%);
    }
  }

  // Variazione scura
  &--dark > &__back {
    &--left {
      background-color: $medium-grey;
    }

    &--right {
      background-color: $dark-grey;
    }
  }

  &--dark > &__container {
    nav ul li a {
      color: #000000;
      background-color: #ffffff;
    }

    .menu__info ul li,
    .menu__info ul li a {
      color: #ffffff;
    }

    .menu__social span {
      color: #ffffff;
    }

    .social-icon {
      filter: invert(0);
    }
  }
}

body.dark-logo {
  .menu {
    &__back {
      &--left {
        background-color: $medium-grey;
      }

      &--right {
        background-color: $dark-grey;
      }
    }

    &__container {
      nav ul li a {
        color: #000000;
        background-color: #ffffff;
      }

      .menu__info ul li,
      .menu__info ul li a {
        color: #ffffff;
      }

      .menu__social span {
        color: #ffffff;
      }

      .social-icon {
        filter: invert(0);
      }
    }
  }
}

.menu__back {
  position: absolute;
}

// Rettangolo sfondo
.menu__back--left {
  width: 100%;
  height: 100%;
  transform: translateY(-100%);
  z-index: 1;
}

// Triangolo sfondo
.menu__back--right {
  width: 100vmax;
  height: 100vmax;
  top: 50%;
  transform: translate(calc(60vw + 70%), -50%) rotate(45deg);
  z-index: 1;
}

.menu__container {
  position: relative;
  z-index: 3;
  height: 100%;
  width: 100%;
  padding: 8em 1em 0 1em;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  row-gap: 4em; // TEMP

  // Versione desktop
  @include respond-to('medium') {
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 2fr 1fr;
    gap: 0 10%; //TEMP
    padding: 0;
  }

  // Navigazione menù
  nav {
    ul > li {
      clip-path: inset(0 0 0 0);
    }

    &.disabled {
      cursor: not-allowed;
      position: relative;

      &::after {
        display: block;
        content: 'Work in progress';
        position: absolute;
        color: #ffffff;
        background-color: #e84118;
        //border: 2px solid #000000;
        box-shadow: 4px 3px 8px rgba(0, 0, 0, 0.5);
        top: 35%;
        left: -20%;
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: $weight-bold;
        padding: 0.5em 1em;
        white-space: nowrap;
        transform: rotate(50deg);

        @include respond-to('medium') {
          font-size: 2.5rem;
        }
      }

      ul > li {
        opacity: 0.2;
        transform: none !important;

        &.disabled {
          pointer-events: none;
          opacity: 0.1;
        }

        a {
          opacity: 1 !important;
          cursor: not-allowed;
        }
      }
    }

    // Versione desktop
    @include respond-to('medium') {
      grid-row: 1 / 3;
      justify-self: center;
      align-self: center;
    }

    ul {
      list-style-type: none;
      pointer-events: none; //Hack per selezionare tutti i fratelli in hover

      &:hover > li:not(.disabled):not(:hover) {
        //// transform: scale(0.95);

        a {
          //TEMP - varianti colore da gestire
          /* color: #ffffff;
          background-color: #000000;*/
          opacity: 0.3;
        }
      }

      li {
        overflow: hidden;
        pointer-events: auto; //Hack per selezionare tutti i fratelli in hover
        transform-origin: left center;
        transition: transform 300ms ease;

        &.disabled {
          pointer-events: none;
          opacity: 0.1;
        }

        // Animazione hover
        &:not(.disabled):hover {
          transform: scale(1.05) translate(2%, 2%);
        }

        &:not(:last-child) {
          margin-bottom: 1em; // TEMP
        }

        a {
          display: inline-block;
          text-decoration: none;
          text-transform: uppercase;
          white-space: nowrap;
          font-weight: $weight-bold;
          font-size: 2.3rem; // TEMP
          padding: 0.3em 0.4em;
          transition: all 300ms linear;

          // Versione desktop
          @include respond-to('medium') {
            font-size: 3rem; //TEMP
          }
        }
      }
    }
  }
}

// Informazioni e contatti
.menu__info {
  grid-row: 2;

  // Versione desktop
  @include respond-to('medium') {
    grid-row: 1 / 2;
    align-self: end;
    font-weight: $weight-semiBold;
  }

  ul {
    list-style-type: none;

    li {
      transform-origin: -100% 50%;
      display: block;
      margin-bottom: 1.5em;
      font-size: 1rem; // TEMP

      // Versione desktop
      @include respond-to('medium') {
        margin-bottom: 1em;
      }

      &.menu__info-title {
        font-weight: $weight-light;
      }

      a {
        text-decoration: none;
      }
    }
  }
}

// Icone social
.menu__social {
  $span-size: 1rem;
  display: flex;
  flex-direction: column;
  padding-top: $span-size * 5;
  position: relative;

  span {
    display: block;
    transform-origin: 0 100%;
    transform: rotate(90deg) translateY(-30%);
    font-size: $span-size;
    position: absolute;
    top: -$span-size * 2.5;
    white-space: nowrap;
  }

  // Versione desktop
  @include respond-to('medium') {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    gap: 20px 0.5em;
    padding: 0;
    margin-top: 1em; //TEMP
    align-self: start;
    justify-self: start;

    span {
      position: static;
      transform: none;
      grid-column: 1 / 4;
    }
  }
}

.social-icon {
  display: block;
  width: 18px;
  height: 18px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  img {
    width: 18px;
    height: 18px;
  }

  // Versione desktop
  @include respond-to('medium') {
    &:not(:last-child) {
      margin-bottom: 0;
      grid-row: 2/3;
    }
  }
}
